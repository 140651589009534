import { Button, Dialog, Spinner } from 'components/ui';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ConfigAgenda } from 'types/calendario/configAgenda';
import useCalendar from 'views/calendario/hooks/useCalendar';

interface DialogProps {
    data: any;
    values: any;
    setIsLoadingEvents?: (loading: boolean) => void;
    atualizarAgenda?: any;
    isOpen: boolean;
    onClose: () => void;
}

const DialogoEditarAgenda = ({
    data,
    values,
    setIsLoadingEvents,
    atualizarAgenda,
    isOpen,
    onClose,
}: DialogProps) => {
    const { botaoEditarAgendamentoClick } = useCalendar();
    const [localLoading, setLocalLoading] = useState(false);

    /**
     * @param edicaoTipo
     * 1: Somente esta
     * 2: Esta e as seguintes
     */
    const handleEditarAgenda = async (edicaoTipo: number) => {
        if (!data?.id) return;

        try {
            setLocalLoading(true); 
            await botaoEditarAgendamentoClick(data, values, edicaoTipo, onClose);
            toast.success('Agenda editada com sucesso!');
            await atualizarAgenda && atualizarAgenda(); 
        } catch (error) {
            console.error('Erro ao editar o agendamento:', error);
            toast.error('Ocorreu um erro ao editar o agendamento.');
        } finally {
            setLocalLoading(false); 
            onClose(); 
        }
    };

    const tipoAg: { [key: string]: string } = {
        BLOQUEIO: 'bloqueio',
        NORMAL: 'agenda',
    };

    return (
        <Dialog
            overlayClassName="z-40"
            isOpen={isOpen}
            onClose={onClose}
            onRequestClose={onClose}
        >
            <div className="relative">

                <h6 className="mb-4 font-medium">
                    Edição de {data?.descricao}
                </h6>
                <p>
                    {data?.categoria === 'NORMAL' ? 'Esta' : 'Este'} é{' '}
                    {data?.categoria === 'NORMAL' ? 'uma' : 'um'}{' '}
                    {tipoAg[data?.categoria as string]} recorrente. O que deseja editar?
                </p>
                <div className="text-right mt-6 flex flex-col md:flex-row gap-2">
                    <Button
                        variant="solid"
                        onClick={() => handleEditarAgenda(0)}
                        disabled={localLoading} 
                    >
                        Somente Esta
                    </Button>
                    <Button
                        variant="solid"
                        onClick={() => handleEditarAgenda(1)}
                        disabled={localLoading} 
                        className="flex items-center justify-center gap-2" 
                    >
                        Essa e as Seguintes
                        {localLoading && (
                            <Spinner className="animate-spin" size="20" />
                        )}
                    </Button>
                    <Button onClick={onClose} disabled={localLoading}>Cancelar</Button>
                </div>
            </div>
        </Dialog>
    );
};

export default DialogoEditarAgenda;
