import { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast as toastfy } from 'react-toastify';
import { _atualizarAgenda, _salvarAgenda } from 'services/AgendaService';
import { _buscarClienteByIdUnico } from 'services/ClienteService';
import { RootState } from 'store';
import { setEventos } from 'store/base/clienteSlice';
import { ConfigAgenda } from 'types/calendario/configAgenda';
import { formatWhatsNumber } from 'utils/format';
import { formatarMoedaBRL2 } from 'utils/moneyformatter';

const JSON_DATE_FORMAT = 'yyyyMMdd';
const DATE_AMERICAN_FORMAT = 'yyyy-MM-dd';
const DEFAULT_DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm';

const useCalendar = () => {
    const dispatch = useDispatch();
    const childRef: { current?: { erroSubmit: () => void } } = useRef();
    const clienteEventos = useSelector((state: RootState) => state.base.cliente);
    const [formEditing, setFormEditing] = useState(false);
    const [dialogoExclusaoAgenda, setDialogoExclusaoAgenda] = useState(false);
    const [dialogoExclusaoAgendaSimples, setDialogoExclusaoAgendaSimples] = useState(false);
    const [duplicate, setDuplicate] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const compararHorarios = (horaInicio: string, horaFinal: string) => {
        const [horaInicioHoras, horaInicioMinutos] = horaInicio
            .split(':')
            .map(Number);
        const [horaFinalHoras, horaFinalMinutos] = horaFinal.split(':').map(Number);

        return !(
            horaFinalHoras < horaInicioHoras
      || (horaFinalHoras === horaInicioHoras
        && horaFinalMinutos < horaInicioMinutos)
        );
    };

    const botaoSalvarClick = async (
        values: any,
        onSucess: () => void,
    ) => {
        const auxValues = values;

        if (auxValues.dataInicio === undefined || auxValues.dataInicio === '') {
            toastfy.warning('Data deve ser informada.');
            childRef.current?.erroSubmit();
            return;
        }

        const dtInicio = DateTime.fromFormat(
            auxValues.dataInicio,
            DATE_AMERICAN_FORMAT,
        ).toFormat('dd/MM/yyyy');

        if (!compararHorarios(auxValues.hora, auxValues.horaFim)) {
            toastfy.warning('A hora final não pode ser anterior à hora inicial');
            childRef.current?.erroSubmit();
            return;
        }

        auxValues.dataInicio = `${dtInicio} ${auxValues.hora}`;
        auxValues.dataFim = `${dtInicio} ${auxValues.horaFim}`;
        auxValues.categoria = auxValues.categoria ? auxValues.categoria : 'NORMAL';

        auxValues.valor = auxValues.valor === '' ? '0' : formatarMoedaBRL2(auxValues.valor);

        if (auxValues.tipoRepeticao === 'NAO_SE_REPETE') auxValues.qtdeRepeticao = 1;
        if (
            auxValues.tipoRepeticao !== 'NAO_SE_REPETE'
      && !auxValues.qtdeRepeticao
        ) {
            toastfy.warning('Informe a quantidade de sessões');
            childRef.current?.erroSubmit();
            return;
        }

        try {
            const resultado = await _salvarAgenda(auxValues);
            
            const eventosLista = JSON.parse(JSON.stringify(clienteEventos.eventos));

            resultado.data?.forEach((evt: ConfigAgenda) => {
                if (!evt.id) return;
                const dataStr = DateTime.fromFormat(
                    evt.dataInicio,
                    DEFAULT_DATE_TIME_FORMAT,
                ).toFormat(JSON_DATE_FORMAT);

                if (eventosLista[dataStr] === undefined) {
                    eventosLista[dataStr] = {};
                }

                eventosLista[dataStr][evt.id] = evt;
            });

            dispatch(setEventos(eventosLista));

            onSucess();
        } catch (error) {
            childRef.current?.erroSubmit();

            if ((error as AxiosError).response?.status === 409) {
                const message = values.categoria === 'NORMAL'
                    ? 'Conflito de agenda. Não foi possível salvar.'
                    : 'Não é possível bloquear um período que já tem agendamento.';
                toastfy.warning(message);
            } else {
                toastfy.error('Ocorreu um erro ao tentar salvar');
            }
        }
    };

    const botaoEditarAgendamentoClick = async (
        data: any,
        values: any,
        alterarSequencia: number,
        onSuccess: () => void = () => {},
    ) => {
        const auxValues = values;
        setSubmitting(true);

        const dataStr = DateTime.fromFormat(
            auxValues.dataInicio,
            'yyyy-MM-dd',
        ).toFormat(JSON_DATE_FORMAT);
        const dtInicio = DateTime.fromFormat(
            auxValues.dataInicio,
            'yyyy-MM-dd',
        ).toFormat('dd/MM/yyyy');

        if (!compararHorarios(auxValues.hora, auxValues.horaFim)) {
            toastfy.warning('A hora final não pode ser anterior à hora inicial');

            childRef.current?.erroSubmit();
            return;
        }

        // auxValues.dataInicio = `${dtInicio} ${auxValues.hora}`;
        // auxValues.dataFim = `${dtInicio} ${auxValues.horaFim}`;
        auxValues.tipo = 0;
        auxValues.valor = formatarMoedaBRL2(auxValues.valor);

        if (!data.id) return;

        try {
            await _atualizarAgenda(data?.id, auxValues, alterarSequencia);
            onSuccess();
            setFormEditing(false);
        } catch (error) {
            if ((error as AxiosError).response?.status === 409) {
                const message = data.categoria === 'NORMAL'
                    ? 'Conflito de agenda. Não foi possível salvar.'
                    : 'Não é possível bloquear um período que já tem agendamento.';
                toastfy.warning(message);
            } else {
                console.log('error', error);
                toastfy.error('Ocorreu um erro ao tentar salvar');
            }
        }
        setSubmitting(false);
    };

    const apagarAgendaClick = async (evt: any) => {
        if (evt.agrupamento !== undefined && evt.agrupamento !== null) {
            setDialogoExclusaoAgenda(true);
        } else {
            if (!evt.id) return;
            setDialogoExclusaoAgendaSimples(true);
        }
    };

    const formatTitle = (inicio: string, fim: string) => {
        if (!inicio || !fim) return;

        const date = inicio.split(' ')[0];
        const horaInicio = inicio.split(' ')[1];
        const horaFinal = fim.split(' ')[1];

        return `${date} das ${horaInicio} às ${horaFinal}`;
    };

    const whatsappClick = async (data: ConfigAgenda) => {
        if (data) {
            const response = await _buscarClienteByIdUnico(data?.clienteId);
            const cliente = response.data;
            const numero = formatWhatsNumber(cliente.telefone);

            window
                .open(`https://api.whatsapp.com/send?phone=${numero}`, '_blank')
                ?.focus();
        }
    };

    return {
        formEditing,
        dialogoExclusaoAgenda,
        dialogoExclusaoAgendaSimples,
        duplicate,
        submitting,
        botaoSalvarClick,
        botaoEditarAgendamentoClick,
        apagarAgendaClick,
        formatTitle,
        setDuplicate,
        setFormEditing,
        setDialogoExclusaoAgenda,
        setDialogoExclusaoAgendaSimples,
        childRef,
        whatsappClick,
    };
};

export default useCalendar;
